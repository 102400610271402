import React, { ChangeEvent } from "react";
import styled from "@emotion/styled";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { t } from "i18next";

interface InputAreaProps {
    input: string;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    sendMessage: () => void;
    hasText: boolean;
}

const InputArea: React.FC<InputAreaProps> = ({
    input,
    handleInputChange,
    sendMessage,
    hasText,
}) => {
    return (
        <Container>
            <StyledInput
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyDown={(e) => e.key === "Enter" && sendMessage()}
                placeholder={t("chatbot.inputPlaceholder")}
            />
            <SendBtn onClick={sendMessage}>
                <ArrowCircleUpIcon
                    sx={{
                        fontSize: "35px",
                        color: hasText ? "#363434" : "#E53935",
                    }}
                />
            </SendBtn>
        </Container>
    );
};

export default InputArea;

const StyledInput = styled("input")({
    width: "80%",
    border: "none",
    borderRadius: "5px",
    height: "45px",
    fontFamily: "Poppins, sans-serif",
    fontSize: "15px",
    "&:focus": {
        outline: "1px #c1c0b9 solid",
    },
    "@media(min-width:768px)": {
        fontSize: "16px",
    },
});

const SendBtn = styled("button")({
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontSize: "18px",
    width: "50px",
    height: "45px",
    backgroundColor: "#F5F5F5",
});

const Container = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
});
