import {
    Autocomplete,
    Button,
    Grid,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    createFilterOptions,
    styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/fr";
import { Cities, CitiesFromGovernment } from "../App";
import { Modal } from "../common/components/Modal";
import { useAppDispatch } from "../common/redux/reduxHooks";
import { CreateDisponibiliteStructure } from "../structures/model/StructureModel";
import { createProject } from "../structures/redux/projectAction";
import { GetTranslatedProfessions } from "../utils/AvailableJobsUtils";
import { language, LanguageType } from "../utils/LanguageUtils";
import "./ProjectComponent.css";

import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";
import { useTranslation } from "react-i18next";
import React from "react";

const initialValueProject: CreateDisponibiliteStructure = {
    ville: "",
    profession: "",
    selection: [""],
    description: "",
    type: "",
    dateDemarrage: "",
    dateFin: "",
};

export const ProjectComponent = () => {
    const [alignmentJour, setAlignmentJour] = React.useState("");
    const [alignmentSemaine, setAlignmentSemaine] = React.useState("");
    const [dayOpen, setDayOpen] = React.useState(false);
    const [startDateValue, setStartDateValue] = React.useState<Date | null>(
        null
    );
    const [weekOpen, setWeekOpen] = React.useState(false);
    const [endDateValue, setEndDateValue] = React.useState<Date | null>(null);
    const [projectData, setProjectData] =
        React.useState<CreateDisponibiliteStructure>(initialValueProject);
    const [isAutocompleteVilleOpen, setIsAutocompleteVilleOpen] =
        React.useState<boolean>(false);
    const [titleModal, setTitleModal] = React.useState<string>("");
    const [messageModal, setMessageModal] = React.useState<string>("");
    const dispatch = useAppDispatch();
    const [isDisplayPopUpCreateProject, setIsDisplayPopUpCreateProject] =
        React.useState(false);
    const [arrayOfErrorMessage, setArrayOfErrorMessage] = React.useState<
        string[]
    >([]);
    const { t } = useTranslation();
    const lang: LanguageType = language();
    const translatedProfessions = GetTranslatedProfessions();
    const handleAlignmentJour = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentJour(newAlignment);
        }
    };
    function getLocalISOString(date: Date) {
        return moment(new Date(date)).toISOString(true).split("+")[0];
    }

    const handleAlignmentSemaine = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentSemaine(newAlignment);
        }
    };

    const changeDatePicker = (x: Date | null, event: any, type: string) => {
        if (x) {
            if (type === "startdate" && x) {
                setProjectData({
                    ...projectData,
                    dateDemarrage: getLocalISOString(x),
                });
                setStartDateValue(x);
            } else if (type === "enddate" && x) {
                setEndDateValue(x);
                setProjectData({
                    ...projectData,
                    dateFin: getLocalISOString(x),
                });
            }
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (projectData.profession.length < 3) {
            setIsDisplayPopUpCreateProject(true);
            setTitleModal(t("error"));
            setMessageModal(t("error.profession"));
            return;
        }
        let selection =
            alignmentJour === alignmentSemaine
                ? ["indifferent"]
                : [alignmentJour, alignmentSemaine];
        let values = {
            ...projectData,
            selection: selection,
            dateDemarrage: startDateValue
                ? getLocalISOString(startDateValue)
                : null,
            dateFin: endDateValue ? getLocalISOString(endDateValue) : null,
        };
        dispatch(createProject(values)).then((res: any) => {
            if (!res.error) {
                setArrayOfErrorMessage([]);
                setIsDisplayPopUpCreateProject(true);
                setMessageModal(lang.createProjectValidation);
                setTitleModal(lang.congratulation);
            } else {
                setIsDisplayPopUpCreateProject(true);
                let errors: string[] = [];
                res.payload.response.data.violations.forEach(
                    (element: { message: string }, i: number) => {
                        errors.push(element.message);
                    }
                );
                setMessageModal("Liste des erreurs :");
                setArrayOfErrorMessage(errors);
                setTitleModal("Erreur");
            }
        });
    };

    const filterOptions = createFilterOptions({
        matchFrom: "start",
        limit: 100,
        stringify: (Cities: CitiesFromGovernment) => Cities.nom,
    });

    return (
        <ProjectContainer>
            <Grid item sx={{ mt: 6 }}>
                <Typography
                    sx={{
                        gridArea: "title",
                        fontSize: "3ex",
                        width: "max-content",
                        fontWeight: "bold",
                        color: "#3b3c36",
                    }}
                >
                    {lang.createProject}
                </Typography>
            </Grid>

            <InputsSection>
                <InputsContainer>
                    <Stack
                        sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                        }}
                    >
                        <Stack sx={{ flexDirection: "row", gap: "1rem" }}>
                            <Autocomplete
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    width: "160px",
                                    height: "fit-content",
                                    color: "#3b3c36",
                                    "& fieldset": { border: "none" },
                                    "@media(width >= 1200px)": {
                                        width: "200px",
                                    },

                                    "@media(width >= 1900px)": {
                                        width: "280px",
                                    },
                                }}
                                disablePortal
                                freeSolo
                                id="combo-box"
                                onInputChange={(event, newInputValue) => {
                                    setProjectData({
                                        ...projectData,
                                        profession: newInputValue,
                                    });
                                }}
                                options={translatedProfessions.sort()}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="profession"
                                        id="profession"
                                        placeholder="Profession"
                                        sx={{ color: "#3b3c36" }}
                                    />
                                )}
                            />
                            <Autocomplete
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    width: "160px",
                                    height: "fit-content",
                                    color: "#3b3c36",
                                    "& fieldset": { border: "none" },
                                    "@media(width >= 1200px)": {
                                        width: "200px",
                                    },
                                    "@media(width >= 1900px)": {
                                        width: "280px",
                                    },
                                }}
                                disablePortal
                                id="combo-box"
                                onInputChange={(event, newInputValue) => {
                                    setProjectData({
                                        ...projectData,
                                        ville: newInputValue,
                                    });
                                    if (newInputValue.length >= 2)
                                        setIsAutocompleteVilleOpen(true);
                                    if (event.type === "click")
                                        setIsAutocompleteVilleOpen(false);
                                }}
                                noOptionsText={"Aucune proposition"}
                                open={isAutocompleteVilleOpen}
                                onBlur={() => setIsAutocompleteVilleOpen(false)}
                                includeInputInList
                                getOptionLabel={(option) => option.nom}
                                filterSelectedOptions
                                filterOptions={filterOptions}
                                options={Cities}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="ville"
                                        id="ville"
                                        placeholder={t("search.city")}
                                    />
                                )}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.code}>
                                            {option.nom}
                                        </li>
                                    );
                                }}
                                onClickCapture={() =>
                                    setIsAutocompleteVilleOpen(false)
                                }
                            />
                        </Stack>
                        <Stack
                            sx={{
                                minWidth: "max-content",
                                flexDirection: "row",
                                gap: "1rem",
                            }}
                            flexWrap={"nowrap"}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="fr"
                            >
                                <StyledDatePicker
                                    disablePast
                                    defaultValue={new Date()}
                                    open={dayOpen}
                                    value={startDateValue}
                                    onOpen={() => setDayOpen(true)}
                                    onClose={() => setDayOpen(false)}
                                    label={
                                        !startDateValue
                                            ? t("select.date.start")
                                            : null
                                    }
                                    views={["day"]}
                                    format="DD/MM/YYYY"
                                    onChange={(x, event) =>
                                        changeDatePicker(
                                            x as Date | null,
                                            event,
                                            "day"
                                        )
                                    }
                                    slotProps={{
                                        textField: {
                                            onClick: () => setDayOpen(true),
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider
                                adapterLocale="fr"
                                dateAdapter={AdapterDayjs}
                            >
                                <StyledDatePicker
                                    disablePast
                                    defaultValue={new Date()}
                                    open={weekOpen}
                                    value={endDateValue}
                                    onOpen={() => setWeekOpen(true)}
                                    onClose={() => setWeekOpen(false)}
                                    onChange={(x, event) =>
                                        changeDatePicker(
                                            x as Date | null,
                                            event,
                                            "day"
                                        )
                                    }
                                    slotProps={{
                                        textField: {
                                            onClick: () => setWeekOpen(true),
                                        },
                                    }}
                                    label={
                                        !endDateValue
                                            ? t("select.date.end")
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Stack>
                    {/* Jour / Nuit*/}
                    <Stack
                        sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "1rem",
                        }}
                    >
                        <ButtonGroup
                            value={alignmentJour}
                            exclusive
                            id="Jour"
                            size="medium"
                            onChange={handleAlignmentJour}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="jour"
                                aria-label="left aligned"
                            >
                                {t("select.day")}
                            </MyToggleButton>
                            <MyToggleButton value="nuit" aria-label="centered">
                                {t("select.night")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ButtonGroup>

                        <ButtonGroup
                            value={alignmentSemaine}
                            exclusive
                            id="Semaine"
                            size="medium"
                            onChange={handleAlignmentSemaine}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="semaines"
                                aria-label="left aligned"
                            >
                                {t("select.period.weekend")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="weekend"
                                aria-label="centered"
                            >
                                Week-End
                            </MyToggleButton>
                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ButtonGroup>
                    </Stack>
                </InputsContainer>
                <TextareaSection>
                    <Textarea>
                        <TextField
                            placeholder={t("project.description")}
                            defaultValue={projectData.description}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setProjectData({
                                    ...projectData,
                                    description: event.target.value,
                                });
                            }}
                            minRows={2}
                            maxRows={3}
                            inputProps={{ maxLength: 100 }}
                            name="description"
                            id="outlined-multiline-flexible"
                            multiline
                        />
                    </Textarea>
                    <Submit type="submit" onClick={handleSubmit}>
                        <TaskAltSharpIcon
                            fontSize="small"
                            sx={{
                                width: 58,
                                height: 58,
                            }}
                        />
                    </Submit>
                    <Modal
                        open={isDisplayPopUpCreateProject}
                        title={titleModal}
                        message={messageModal}
                        arrayOfMessage={arrayOfErrorMessage}
                        credentials=" "
                        onClose={() => setIsDisplayPopUpCreateProject(false)}
                        onValidation={() => {
                            setIsDisplayPopUpCreateProject(false);
                        }}
                        titleButtonValidation={t("button.modal.valid")}
                    ></Modal>
                </TextareaSection>
            </InputsSection>
        </ProjectContainer>
    );
};

const ProjectContainer = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    marginTop: "3rem",
    gap: "1rem",
    padding: "1rem",
    alignItems: "center",
    width: "100%",
    background:
        "linear-gradient(to right top, #c1e1c1, #c2e1c3, #c3e1c5, #c5e1c7, #c6e1c9, #c6e1c9, #c6e1c9, #c6e1c9, #c5e1c7, #c3e1c5, #c2e1c3, #c1e1c1)",
});

const InputsSection = styled(Stack)({
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
    marginTop: "1rem",

    "@media(width >= 990px)": {
        flexDirection: "row",
    },
});

const InputsContainer = styled(Stack)({
    flexDirection: "column",
    width: "100%",
    gap: "1.5rem",
    justifyContent: "center",
    alignItems: "center",

    "@media(width >= 990px)": {
        flexDirection: "row",
        width: "60%",
    },

    "@media(width >= 1900px)": {
        width: "50%",
    },
});

const MyToggleButton = styled(ToggleButton)({
    background: "white",
    boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 3px 3px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    borderRadius: "5px",
    fontSize: "13px",
    width: "113px",

    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#36454F",
        backgroundColor: "#FF8A80",
    },
    "&:hover": { backgroundColor: "#E5E4E2" },

    "@media (width >= 765px)": {
        width: "95px",
    },

    "@media(width >= 1200px)": {
        width: "120px",
    },
});

const ButtonGroup = styled(ToggleButtonGroup)({
    display: "flex",
    flexDirection: "row",
    borderRadius: "10px",
    height: "55px",
    width: "335px",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

    "@media(width >= 768px)": {
        width: "280px",
        height: "45px",
    },

    "@media(width >= 1200px)": {
        width: "350px",
    },
});

const StyledDatePicker = styled(DatePicker)({
    width: "160px",
    backgroundColor: "white",
    borderRadius: "5px",
    color: "#3b3c36",

    "@media(width >= 1200px)": {
        width: "200px",
    },

    "@media(width >= 1900px)": {
        width: "280px",
    },
});

const Submit = styled(Button)({
    borderRadius: "50%",
    width: "50px",
    height: "65px",
    padding: "0.5rem",
    alignSelf: "center",
    color: "white",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    background:
        "linear-gradient(to bottom, #fdaaaa, #fda2a2, #fc9999, #fc9191, #fb8888, #f98282, #f87d7d, #f67777, #f47473, #f27070, #f06d6c, #ee6969)",
});

const TextareaSection = styled(Stack)({
    flexDirection: "row",
    height: "125px",
    width: "100%",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",

    "@media(width >= 990px)": {
        gap: "3rem",
        width: "30%",
    },

    "@media(width >= 1900px)": {
        width: "35%",
    },
});

const Textarea = styled(Stack)({
    borderRadius: "10px",
    height: "100%",
    width: "100%",
    backgroundColor: "white",

    "& fieldset": { border: "none" },

    "@media(min-width: 768px)": {
        width: "50%",
    },

    "@media(min-width: 990px)": {
        width: "100%",
    },
});
