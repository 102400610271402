import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    initialStateReducer,
    IStateReducer,
} from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { ResponseToken } from "../model/ResponseToken";
import { invalidateToken } from "./authAction";
import { ResponseViolations } from "../../structures/redux/signUpAction";
import { RootState } from "../../common/redux/store";

export const initialAuthData: ResponseToken = {
    token: "",
    roles: [],
    refresh_token: "",
    id: "",
    isLoggedIn: false,
};

export const initialAuthState: IStateReducer<ResponseToken> = {
    ...initialStateReducer,
    data: initialAuthData,
    isLoggedIn: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialAuthState,
    reducers: {
        setLoginStart: (state: IStateReducer<ResponseToken>) => {
            state.isLoader = true;
            state.error = undefined;
            state.isSuccess = false;
            state.isLoggedIn = false;
            return state;
        },
        setLogin: (
            state: IStateReducer<ResponseToken>,
            action: PayloadAction<ResponseToken>
        ) => {
            state.data = { ...action.payload, isLoggedIn: true };
            state.isLoader = false;
            state.isSuccess = true;
            state.isLoggedIn = true;
            return state;
        },
        setLoginErrorRoles: (
            state: IStateReducer<ResponseToken>,
            action: PayloadAction<AxiosError<ResponseMessage | any>>
        ) => {
            state.isLoader = false;
            state.error = action.payload;
            state.isSuccess = false;
            state.isLoggedIn = false;
            return state;
        },
        setLoginError: (
            state: IStateReducer<ResponseToken>,
            action: PayloadAction<AxiosError<ResponseMessage | any>>
        ) => {
            state.isLoader = false;
            state.error = action.payload.response?.data;
            state.isSuccess = false;
            state.isLoggedIn = false;
            return state;
        },
        setForgotPasswordError: (
            state: IStateReducer<ResponseToken>,
            action: PayloadAction<AxiosError<ResponseMessage | any>>
        ) => {
            state.isLoader = false;
            state.isErrorPasswordRequest = action.payload.response?.data;
            state.isSuccessPasswordRequest = false;
            state.isSuccess = false;
            return state;
        },
        setForgotPasswordSuccess: (state: IStateReducer<ResponseToken>) => {
            state.isLoader = false;
            state.isSuccessPasswordRequest = true;
            state.isSuccess = false;
            return state;
        },
        setConfirmPasswordError: (
            state: IStateReducer<ResponseToken>,
            action: PayloadAction<AxiosError<ResponseMessage | any>>
        ) => {
            state.isLoader = false;
            state.isErrorPasswordConfirm = true;
            state.isSuccessPasswordConfirm = false;
            state.isSuccess = false;
            state.error = action.payload.response?.data;
            return state;
        },
        setConfirmPasswordSuccess: (state: IStateReducer<ResponseToken>) => {
            state.isLoader = false;
            state.isSuccessPasswordConfirm = true;
            state.isErrorPasswordConfirm = false;
            state.isSuccess = true;
            return state;
        },
    },
    extraReducers(builder) {
        builder.addCase(invalidateToken.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.isLoggedIn = false;
        });
        builder.addCase(invalidateToken.fulfilled, (state, action) => {
            state.isLoader = false;
            state.isSuccess = true;
            state.isLoggedIn = false;
        });
        builder.addCase(
            invalidateToken.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseViolations>;
                state.isLoggedIn = false;
            }
        );
    },
});

export const {
    setLogin,
    setLoginError,
    setLoginStart,
    setForgotPasswordError,
    setForgotPasswordSuccess,
    setConfirmPasswordSuccess,
    setConfirmPasswordError,
    setLoginErrorRoles,
} = authSlice.actions;
export default authSlice;

export const selectCurrentUserId = (state: RootState) => state.auth.data.id;
export const selectCurrentUserToken = (state: RootState) =>
    state.auth.data.token;
