import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { RouteAPI, api } from "../../common/service/apiService";
import { UserProfessionnelResponse } from "../model/Disponibilite";

export const getDisponibilities = createAsyncThunk<
    UserProfessionnelResponse[],
    string | null
>("search/getAll", async (params: string | null, { rejectWithValue }) => {
    try {
        let response =
            params === null
                ? await api(
                      RouteAPI.DISPONIBILITE_PROFESSIONNELS + "?enabled=true",
                      "GET"
                  )
                : await api(
                      RouteAPI.DISPONIBILITE_PROFESSIONNELS +
                          "?enabled=true" +
                          params,
                      "GET"
                  );
        return response.data as UserProfessionnelResponse[];
    } catch (error) {
        return rejectWithValue(error as AxiosError<ResponseMessage>);
    }
});

export const postSmart = createAsyncThunk<
    any,
    { id: string; role: "ROLE_PROFESSIONNEL" | "ROLE_STRUCTURE" }
>("search/postSmart", async ({ id, role }, thunkAPI) => {
    try {
        const url =
            role === "ROLE_PROFESSIONNEL"
                ? RouteAPI.DISPONIBILITE_STRUCTURE + "/" + id + "/professionel"
                : RouteAPI.DISPONIBILITE_PROFESSIONNELS +
                  "/" +
                  id +
                  "/structure";
        const response = await api(url, "PATCH");
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error as AxiosError<ResponseMessage>);
    }
});

export const getCity = createAsyncThunk<any, null>(
    "search/getCity",
    async (id, thunkAPI) => {
        try {
            const response = await axios
                .get(RouteAPI.GET_DEPARTEMENT_FROM_GOVERNMENT)
                .then((res) => res.data);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseMessage>
            );
        }
    }
);

// export const getAllDisponibilities = (): ThunkAction<void, RootState, unknown, AnyAction> => {
//   return async(dispatch, getState) => {
//     dispatch(getDisponibilities());
//   }
// }
