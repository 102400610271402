import { CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import fleche2 from "../assets/fleche2.png";
import { RouteApp } from "../common/model/RouteApp";
import { DisponibiliteStructure } from "../structures/model/StructureModel";
import { getEmptyAvatarStructure } from "../utils/FormatUtils";
import "./StructureCard.css";
import { Item, ItemNight } from "./WorkerCard";
import { language, LanguageType } from "../utils/LanguageUtils";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

export const StructureCard: FunctionComponent<DisponibiliteStructure> = (
    props: DisponibiliteStructure
) => {
    const lang: LanguageType = language();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enabled } = props;
    const status = !enabled
        ? lang.waitingValidation
        : props.isInterested
        ? props.isInterested.status
        : props.interested?.length + " Smart";

    function capitalizeFirstLetter(string: string) {
        if (string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }

    function redirectToDetails() {
        navigate(
            RouteApp.MY_PROJECTS_DETAILS + "/" + props.indexOfDisponibility,
            {
                state: props,
            }
        );
    }

    return (
        <CardContainer elevation={3}>
            <CardActionArea onClick={redirectToDetails}>
                <CardHeader
                    subheaderTypographyProps={{
                        width: "auto",
                        maxWidth: "none",
                    }}
                    sx={{ p: 0 }}
                    avatar={
                        <img
                            src={
                                props.profilImageUrl
                                    ? props.profilImageUrl
                                    : getEmptyAvatarStructure()
                            }
                            alt="Profile"
                            id="profilePictureStruct"
                            style={{ maxHeight: 90, maxWidth: 90 }}
                        />
                    }
                    title={
                        <Grid
                            item
                            md={5}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                mt: 1,
                            }}
                        >
                            <Typography
                                gutterBottom
                                variant="body2"
                                sx={{
                                    backgroundColor: "#36454F",
                                    color: "white",
                                    p: 1,
                                    borderRadius: "5px",
                                }}
                            >
                                {new Date(
                                    props.dateDemarrage
                                ).toLocaleDateString("fr-FR")}
                            </Typography>
                            <div>
                                <img
                                    src={fleche2}
                                    alt="Arrow"
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        zIndex: "10",
                                        margin: 0.2,
                                    }}
                                ></img>
                            </div>
                            <Typography
                                gutterBottom
                                variant="body2"
                                sx={{
                                    backgroundColor: "#36454F",
                                    color: "white",
                                    p: 1,
                                    borderRadius: "5px",
                                }}
                            >
                                {props.dateFin
                                    ? new Date(
                                          props.dateFin
                                      ).toLocaleDateString("fr-FR")
                                    : t("select.indifferent")}
                            </Typography>
                        </Grid>
                    }
                    subheader={
                        <Grid item sx={{ width: "auto" }}>
                            <Typography
                                gutterBottom
                                noWrap={true}
                                sx={{
                                    borderTop: "0.1em solid gray",
                                    borderBottom: "0.1em solid gray",
                                    pt: 1,
                                    fontWeight: "bold",
                                    width: "auto",
                                    pb: 1,
                                }}
                            >
                                {props.ville}
                            </Typography>
                            <Typography
                                gutterBottom
                                sx={{
                                    pt: 0,
                                    fontWeight: "bold",
                                    width: "auto",
                                    pb: 1,
                                    borderBottom: "0.1em solid gray",
                                }}
                            >
                                {t(`${props.profession}`)}
                            </Typography>
                        </Grid>
                    }
                />
                <CardContent>
                    <Typography
                        align="center"
                        sx={{ mb: 2, borderRadius: "15px" }}
                    >
                        {props.description}
                    </Typography>
                    <Grid
                        container
                        mb={1}
                        spacing={1}
                        columns={16}
                        sx={{
                            justifyContent: "center",
                            gap: "0.5rem",
                        }}
                        flexWrap={"nowrap"}
                    >
                        {props.selection &&
                            props.selection.map((e) => {
                                return e !== t("select.night") ? (
                                    <Item>{capitalizeFirstLetter(e)}</Item>
                                ) : (
                                    <ItemNight>
                                        {capitalizeFirstLetter(e)}
                                    </ItemNight>
                                );
                            })}
                    </Grid>
                </CardContent>
                <Grid
                    container
                    columns={16}
                    bgcolor={"#3d3d3d"}
                    textAlign={"center"}
                    pt={0.5}
                    pb={0.5}
                >
                    <Grid item xs={16}>
                        <Typography
                            variant="h6"
                            color={"white"}
                            sx={{ fontWeight: "bold" }}
                        >
                            {status}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </CardContainer>
    );
};

const CardContainer = styled(Card)({
    marginTop: "1rem",
    borderRadius: "5%",
    display: "inline-flex",
    width: "360px",
    opacity: "!enabled ? 0.65 : 1",
    ":hover": {
        boxShadow: "enabled ? 20 : 0", // theme.shadows[20]
    },

    "@media(width >= 990px)": {
        width: "420px",
    },
});
