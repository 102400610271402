import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import ChatBotAvatar from "../assets/ChatbotAvatarIcon.png";
import UserAvatar from "../assets/UserAvatar.png";
import {
    ProfessionalAvailability,
    StructureAvailability,
} from "./model/ChatbotResponse";
import AvailabilityCard from "./AvailabilityCard";

export interface TextMessage {
    type: "text";
    content: string;
    user: "bot" | "user";
}

export interface AvailabilitiesMessage {
    type: "availability";
    content: StructureAvailability[] | ProfessionalAvailability[];
    user: "bot";
}

export type Message = TextMessage | AvailabilitiesMessage;

export interface ChatMessagesProps {
    messages: Message[];
}

const renderMessage = ({ type, content, user }: Message, index: number) => {
    if (user === "user") {
        return (
            <UserContainer key={index} className={`message ${user}`}>
                <img
                    src={UserAvatar}
                    alt="User Avatar"
                    width="40px"
                    height="40px"
                />
                <UserTypography style={{ whiteSpace: "pre-line" }}>
                    {content}
                </UserTypography>
            </UserContainer>
        );
    }

    if (type === "text") {
        return (
            <BotContainer key={index} className={`message ${user}`}>
                <img
                    src={ChatBotAvatar}
                    alt="Bot Avatar"
                    width="55px"
                    height="55px"
                />
                <BotTypography style={{ whiteSpace: "pre-line" }}>
                    {content}
                </BotTypography>
            </BotContainer>
        );
    }

    if (type === "availability") {
        return (
            <BotContainer key={index} className={`message ${user}`}>
                <img
                    src={ChatBotAvatar}
                    alt="Bot Avatar"
                    width="55px"
                    height="55px"
                />
                <BotTypography style={{ whiteSpace: "pre-line" }}>
                    {content.map((availability, index) => (
                        <AvailabilityCard
                            key={index}
                            availability={availability}
                        />
                    ))}
                </BotTypography>
            </BotContainer>
        );
    }

    return null;
};

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages }) => {
    const [messageCount, setMessageCount] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current && messages.length > messageCount) {
            console.log(
                messageCount,
                messages.length,
                containerRef.current.children
            );
            containerRef.current.children[messageCount].scrollIntoView({
                block: "start",
                behavior: "smooth",
            });
            setMessageCount(messages.length);
        }
    }, [messageCount, messages]);

    return (
        <StyledInputContainer ref={containerRef} className="messages">
            {messages.map((msg, index) => renderMessage(msg, index))}
        </StyledInputContainer>
    );
};

export default ChatMessages;

const StyledInputContainer = styled("div")({
    height: "450px",
    overflowY: "auto",
    width: "100%",
    backgroundColor: "#F5F5F5",
    "::-webkit-scrollbar": {
        width: "10px",
    },
    "::-webkit-scrollbar-thumb": {
        backgroundColor: "#E53935",
        borderRadius: "20px",
        border: "1px solid #f1f1f1",
    },
    "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#E53935",
    },
    "@media(min-width:990px)": {
        height: "520px",
    },
});

const BotContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    margin: "1rem 0",
    backgroundColor: "#ffffff",
    width: "90%",
    borderRadius: "0 30px 30px 0px",
    padding: "0.5rem",
});

const BotTypography = styled(Typography)({
    fontSize: "15px",
    fontFamily: "Poppins, sans-serif",
    color: "#343434",
    padding: "0.5rem",
});

const UserContainer = styled("div")({
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "end",
    margin: "1rem 0",
    backgroundColor: "#d2e7d6",
    borderRadius: "30px 0 0 30px",
    padding: "0.5rem",
    flexDirection: "row-reverse",
});

const UserTypography = styled(Typography)({
    fontSize: "15px",
    fontFamily: "Poppins, sans-serif",
    color: "#343434",
    padding: "0.5rem",
    textAlign: "start",
    "@media(min-width:768px)": {
        textAlign: "end",
    },
});
