import { Box, Button, Paper, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowLeft from "../assets/arrowLeft.svg";
import { RouteApp } from "../common/model/RouteApp";
import { useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { ProjectDetailsComponent } from "./ProjectDetailsComponent";
import {
    DisponibiliteStructure,
    ProfessionnelInterestedByStructure,
} from "./model/StructureModel";
import { refStatus } from "../cards/WorkerCard";

import { useEffect, useState } from "react";
import { SmartAcceptedCard } from "../common/components/SmartAcceptedCard";
import { useTranslation } from "react-i18next";

export const ProjectDetailsPage = () => {
    const { idProject } = useParams();
    const { state } = useLocation();
    const [smartAccepted, setSmartAccepted] =
        useState<ProfessionnelInterestedByStructure>();

    const userSelector: DisponibiliteStructure[] = useAppSelector(
        (state: RootState) => state.user.data.disponibilities
    );
    const { t } = useTranslation();
    const navigate = useNavigate();
    const props = userSelector[Number(idProject)];

    useEffect(() => {
        const personnelValidate = props.interested?.filter(
            (v: ProfessionnelInterestedByStructure) =>
                v.status === refStatus.accepted
        );
        if (personnelValidate) {
            setSmartAccepted(personnelValidate[0]);
        }
    }, [props.interested]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <Box sx={{ mt: 6 }}>
                    <Typography
                        variant="h6"
                        mt={3}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <Button
                            variant="text"
                            size="large"
                            style={{
                                padding: 0,
                                marginTop: "10px",
                                display: "contents",
                                blockSize: "50px",
                            }}
                            onClick={() => navigate(RouteApp.MY_PROJECTS)}
                        >
                            <img
                                src={ArrowLeft}
                                height={50}
                                alt="go_back"
                                style={{ blockSize: "50px" }}
                            />
                        </Button>
                        {t("projects")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 4,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                justifyContent: "center",
                                textAlign: "center",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {userSelector.length}
                        </span>
                    </Typography>
                    <ProjectDetailsComponent
                        {...state}
                    ></ProjectDetailsComponent>
                </Box>
                <Box sx={{ gridArea: "card", mr: 2, mt: 3 }}>
                    <Typography>
                        {props.interested?.length} {t("project.details.smart")}
                    </Typography>
                    <Paper sx={{ ml: "auto", mr: "auto", mt: 10, mb: 10 }}>
                        {smartAccepted && (
                            <SmartAcceptedCard
                                Professionnel={smartAccepted.Professionnel}
                                status="ACCEPTED"
                            ></SmartAcceptedCard>
                        )}
                    </Paper>
                </Box>
            </Box>
        </>
    );
};
