import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
    Alert,
    Box,
    Button,
    Divider,
    Paper,
    Stack,
    Typography,
    styled,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import fleche from "../assets/fleche.png";
import { Modal } from "../common/components/Modal";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch } from "../common/redux/reduxHooks";
import { DisponibiliteStructure } from "./model/StructureModel";
import { deleteProject } from "./redux/projectAction";
import { ItemNight } from "../cards/WorkerCard";
import { useTranslation } from "react-i18next";

export const ProjectDetailsComponent = (props: DisponibiliteStructure) => {
    const [isDisplayPopupDeleteProject, setisDisplayPopupDeleteProject] =
        React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const deleteDispo = () => {
        setisDisplayPopupDeleteProject(false);
        dispatch(deleteProject(props.id));
        navigate(RouteApp.MY_PROJECTS);
    };

    function capitalizeFirstLetter(string: string) {
        if (string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }

    return (
        <>
            <Section>
                <Stack
                    sx={{
                        flexDirection: "row",
                        gap: "1rem",
                    }}
                >
                    <Typography
                        sx={{
                            border: "1px solid #B95757",
                            p: 1.2,
                            borderRadius: "5px",
                        }}
                    >
                        {props.profession}
                    </Typography>
                    <Typography
                        sx={{
                            border: "1px solid #B95757",
                            p: 1.2,
                            borderRadius: "5px",
                        }}
                    >
                        {props.ville}
                    </Typography>
                </Stack>
                <Divider orientation="horizontal" flexItem color="gray" />
                <Divider orientation="vertical" flexItem color="gray" />
                <DateContainer>
                    <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                            backgroundColor: "#696969",
                            color: "white",
                            p: 1.3,
                            borderRadius: "5px",
                            fontSize: "2ex",
                        }}
                    >
                        {new Date(props.dateDemarrage).toLocaleDateString(
                            "fr-FR"
                        )}
                    </Typography>
                    <div>
                        <img
                            src={fleche}
                            alt="Arrow"
                            style={{
                                width: "32px",
                                height: "32px",
                                marginBottom: "0.4rem",
                                zIndex: "10",
                            }}
                        ></img>
                    </div>
                    <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                            backgroundColor: "#696969",
                            color: "white",
                            p: 1.3,
                            borderRadius: "5px",
                            fontSize: "2ex",
                        }}
                    >
                        {props.dateFin
                            ? new Date(props.dateFin).toLocaleDateString(
                                  "fr-FR"
                              )
                            : t("select.indifferent")}
                    </Typography>
                </DateContainer>
                <Divider orientation="horizontal" flexItem color="gray" />
                <Divider orientation="vertical" flexItem color="gray" />
                <Box
                    sx={{
                        display: "inherit",
                        textAlign: "center",
                    }}
                >
                    {props.selection &&
                        props.selection.map((e) => {
                            return e !== t("select.night") ? (
                                <Item>{capitalizeFirstLetter(e)}</Item>
                            ) : (
                                <ItemNight>
                                    {capitalizeFirstLetter(e)}
                                </ItemNight>
                            );
                        })}
                </Box>
                <Delete
                    color="error"
                    onClick={() => setisDisplayPopupDeleteProject(true)}
                    variant="contained"
                    size="medium"
                    endIcon={<HighlightOffIcon />}
                >
                    {t("button.delete.project")}
                </Delete>
            </Section>
            <Modal
                open={isDisplayPopupDeleteProject}
                message={" "}
                onCancel={() => setisDisplayPopupDeleteProject(false)}
                titleButtonCancel={t("button.modal.cancel")}
                title={t("project.delete.confirmation")}
                onClose={() => setisDisplayPopupDeleteProject(false)}
                onValidation={() => deleteDispo()}
                titleButtonValidation={t("button.modal.valid")}
            ></Modal>
            {props.enabled ? null : (
                <Alert
                    color="error"
                    sx={{
                        width: "30%",
                        mr: "auto",
                        ml: "auto",
                        mt: 5,
                    }}
                >
                    {t("project.validation")}
                </Alert>
            )}
        </>
    );
};

const Item = styled(Typography)(() => ({
    background:
        "linear-gradient(to right top, #4f6f52, #668468, #7e9a7e, #96b195, #afc8ad)",
    margin: "0.25rem",
    padding: 10,
    borderRadius: "2px",
    color: "white",
    fontWeight: "bold",
    justifyContent: "center",
    display: "flex",
    fontSize: "16px",
    width: "90px",
    wordWrap: "break-word",
}));

const Section = styled(Paper)({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "0.8rem",
    padding: "2rem",
    marginTop: "1.5rem",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

    "@media(width >= 990px)": {
        flexDirection: "row",
        width: "fit-content",
    },
});

const Delete = styled(Button)({
    width: "190px",
    padding: "0.4rem",
    marginTop: "1rem",

    "@media(width >= 990px)": {
        marginTop: "0",
        width: "fit-content",
        padding: "0.5rem",
    },
});

const DateContainer = styled(Stack)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",

    "@media(width >= 990px)": {
        margin: "0.4rem 0 0 0.5rem",
    },
});
