import React, {
    useState,
    useEffect,
    ChangeEvent,
    useReducer,
    useRef,
} from "react";
import { Box, SpeedDial, Stack, Typography, Avatar } from "@mui/material";
import styled from "@emotion/styled";
import { useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import ChatMessages, { Message } from "./ChatMessages";
import InputArea from "./InputArea";
import ChatbotAvatar from "../assets/ChatbotAvatarIcon.png";
import ChatbotSleep from "../assets/ChatbotSleep.png";
import { api, RouteAPI } from "../common/service/apiService";
import {
    ChatbotJSONResponse,
    ChatbotTextResponse,
} from "./model/ChatbotResponse";
import { t } from "i18next";

interface ChatbotProps {
    kind: string;
    sessionId?: string;
    isLoggedIn: boolean;
}

const Chatbot: React.FC<ChatbotProps> = ({ kind }) => {
    const [messages, addMessages] = useReducer(
        (state: Message[], message: Message | Message[]) => {
            return [...state, message].flat();
        },
        []
    );
    const [input, setInput] = useState<string>("");
    const [role, setRole] = useState<string | null>(kind);
    const [hasText, setHasText] = useState(false);
    const [open, setOpen] = useState<boolean>(() => {
        const savedOpenState = localStorage.getItem("chatbotOpenState");
        return savedOpenState ? JSON.parse(savedOpenState) : false;
    });
    const [loading, setLoading] = useState(false);

    const tokens = useAppSelector((state: RootState) => state.auth);
    const userSelector = useAppSelector((state: RootState) => state.user.data);
    const messagesAdded = useRef(false);

    useEffect(() => {
        if (!userSelector || messagesAdded.current) return;

        if (kind) {
            const initialMessage =
                kind === "structure"
                    ? t("chatbot.structure.helloMessage", {
                          company: userSelector.societe,
                      })
                    : t("chatbot.professional.helloMessage");
            const actionsSuggestion =
                kind === "structure"
                    ? t("chatbot.structure.actionsSuggestion")
                    : t("chatbot.professional.actionsSuggestion");
            addMessages([
                { type: "text", content: initialMessage, user: "bot" },
                { type: "text", content: actionsSuggestion, user: "bot" },
            ]);
            setRole(kind);
            messagesAdded.current = true;
        }
    }, [kind, userSelector, addMessages, setRole]);

    useEffect(() => {
        if (tokens.isSuccess && tokens.data.roles) {
            if (tokens.data.roles.includes("ROLE_PROFESSIONNEL")) {
                setRole("ROLE_PROFESSIONNEL");
            } else if (tokens.data.roles.includes("structure")) {
                setRole("structure");
            } else {
                setRole("user");
            }
        }
    }, [tokens]);

    useEffect(() => {
        const chatbotOpened = sessionStorage.getItem("chatbotOpened");
        if (!chatbotOpened && tokens.isSuccess) {
            setOpen(true);
            sessionStorage.setItem("chatbotOpened", "true");
        }
    }, [tokens.isSuccess]);

    useEffect(() => {
        localStorage.setItem("chatbotOpenState", JSON.stringify(open));
    }, [open]);

    const sendMessage = async () => {
        const newMessage: Message = {
            type: "text",
            content: input,
            user: "user",
        };
        addMessages(newMessage);
        setInput("");

        const payload = {
            message: input,
        };

        setLoading(true);

        try {
            const response: ChatbotTextResponse | ChatbotJSONResponse =
                await api(RouteAPI.CHATBOT_CHAT, "POST", payload).then(
                    (res) => res.data
                );

            if (response.type === "text") {
                addMessages({
                    type: "text",
                    content: response.response,
                    user: "bot",
                });
                return;
            }

            if (response.type === "json") {
                const { data, filters } = response.response || {};

                if (data && data.length > 0) {
                    addMessages([
                        {
                            type: "text",
                            content:
                                filters.type === "structure"
                                    ? t(
                                          "chatbot.structure.availabilitiesFoundMessage"
                                      )
                                    : t(
                                          "chatbot.professional.availabilitiesFoundMessage"
                                      ),
                            user: "bot",
                        },
                        {
                            type: "availability",
                            content: data,
                            user: "bot",
                        },
                    ]);
                    return;
                }

                addMessages({
                    type: "text",
                    content:
                        filters.type === "structure"
                            ? t(
                                  "chatbot.structure.noAvailabilitiesFoundMessage"
                              )
                            : t(
                                  "chatbot.professional.noAvailabilitiesFoundMessage"
                              ),
                    user: "bot",
                });
                return;
            }

            addMessages({
                type: "text",
                content: t("chatbot.errorOccured"),
                user: "bot",
            });
        } catch (error) {
            console.error("Error sending message:", error);
            addMessages({
                type: "text",
                content: t("chatbot.errorSendingMessage"),
                user: "bot",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setInput(text);
        setHasText(text.length > 0);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <SpeedDial
                ariaLabel="SpeedDial chatbot"
                icon={<Avatar src={open ? ChatbotAvatar : ChatbotSleep} />}
                onClick={() => setOpen((prevOpen) => !prevOpen)}
                FabProps={{
                    sx: {
                        bgcolor: "white",
                        boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        "&:hover": {
                            bgcolor: "#FAF9F6",
                        },
                    },
                }}
                sx={{
                    position: "fixed",
                    bottom: 20,
                    right: 16,
                    width: "fit-content",
                    zIndex: 1300,
                }}
            ></SpeedDial>
            {open && (
                <ContainerBot className="w-100 justify-content-center align-items-center flex-row">
                    <StyledSection className="align-items-start justify-content-center mt-4 mb-4">
                        <StyledTitle>{t("chatbot.title")}</StyledTitle>
                        <ChatMessages messages={messages} />
                        {loading && (
                            <BotWritingContainer>
                                <Typography
                                    variant="body1"
                                    sx={{ color: "gray" }}
                                    fontSize={12}
                                >
                                    {t("chatbot.isTyping")}
                                </Typography>
                            </BotWritingContainer>
                        )}
                        <StyledStack className="w-100 align-items-center justify-content-center flex-column">
                            {role !== null && (
                                <InputArea
                                    input={input}
                                    handleInputChange={handleInputChange}
                                    sendMessage={sendMessage}
                                    hasText={hasText}
                                />
                            )}
                        </StyledStack>
                    </StyledSection>
                </ContainerBot>
            )}
        </Box>
    );
};
export default Chatbot;

const StyledSection = styled(Stack)({
    height: "440px",
    overflowY: "auto",
    width: "100%",
    maxWidth: "360px",
    borderRadius: "10px 10px 0 0",
    boxShadow:
        "rgba(0, 0, 0, 0.15) 0px 1px 1px, rgba(0, 0, 0, 0.15) 0px 1px 1px",
});

const StyledStack = styled(Stack)({
    backgroundColor: "#E53935",
    padding: "0.5rem",

    "@media(min-width:768px)": {
        padding: "1rem",
    },
});

const ContainerBot = styled(Stack)({
    position: "fixed",
    bottom: "80px",
    right: "7px",
    zIndex: "1000",
    width: "100%",
    maxWidth: "360px",
    margin: "0 auto",
});

const StyledTitle = styled(Typography)({
    fontFamily: "Poppins, sans-serif",
    fontWeight: "500",
    color: "white",
    padding: "0.5rem",
    backgroundColor: "#E53935",
    textAlign: "center",
    fontSize: "18px",
    "@media(min-width:768px)": {
        padding: "1rem",
    },
});

const BotWritingContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    textAlign: "start",
    padding: "0.5rem",
    backgroundColor: "#ffffff",
});
