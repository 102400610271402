import React, { useState } from "react";
import { api } from "../common/service/apiService";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";

export function UploadDocument() {
    const [isLoading, setIsLoading] = useState(false);
    const [documentName, setDocumentName] = useState("");
    const [documentUri, setDocumentUri] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [description, setDescription] = useState("");
    const { t } = useTranslation();
    const openDocumentPicker = async () => {
        try {
            const fileInput = document.createElement("input");
            fileInput.type = "file";
            fileInput.accept = "application/pdf, image/png, image/jpeg";
            fileInput.click();

            fileInput.onchange = (e: any) => {
                const file = e?.target?.files[0];
                if (file) {
                    setDocumentName(file.name);
                    setDocumentUri(file);
                    setDocumentType(file.type);
                }
            };
        } catch (error) {
            console.error("Error picking document:", error);
        }
    };

    const uploadDocument = async () => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("file", documentUri);
            formData.append("name", documentName);
            formData.append("description", "Document uploaded from web app");
            console.log("formData", formData);

            const response = await api(
                "/api/documents/send",
                "POST",
                formData,
                {
                    "Content-Type": "multipart/form-data",
                }
            );

            console.log("Document uploaded:", response);

            setIsLoading(false);
            alert("Document uploaded successfully!");
        } catch (error) {
            console.error("Error uploading document:", error);
            setIsLoading(false);
            alert("Failed to upload document. Please try again later.");
        }
    };

    return (
        <Container>
            <InputSection>
                <Button onClick={openDocumentPicker}>
                    {t("button.upload.document")}
                </Button>
                <Input
                    type="text"
                    placeholder="Document Name"
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                />
                <Input
                    type="text"
                    placeholder={t("profile.document.description")}
                    value={description}
                    onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                    }) => setDescription(e.target.value)}
                />
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <BtnSend onClick={uploadDocument} disabled={!documentUri}>
                        {t("button.send.document")}
                        <SendIcon />
                    </BtnSend>
                )}
            </InputSection>
            {documentUri && (
                <div>
                    <strong>Preview:</strong>
                    {documentType.startsWith("image") ? (
                        <img
                            src={documentUri}
                            alt={documentName}
                            style={{
                                maxWidth: "100%",
                                maxHeight: 300,
                                marginTop: 10,
                            }}
                        />
                    ) : (
                        <embed
                            src={documentUri}
                            type="application/pdf"
                            style={{
                                width: "100%",
                                height: 300,
                                marginTop: 10,
                            }}
                        />
                    )}
                </div>
            )}
        </Container>
    );
}

export default UploadDocument;

const Button = styled("button")({
    fontWeight: 400,
    borderRadius: "5px",
    width: "200px",
    padding: "0.6rem",
    fontSize: "14px !important",
    textAlign: "center",
    letterSpacing: 0.5,
    cursor: "pointer",
});

const Container = styled(Stack)({
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
});

const InputSection = styled(Stack)({
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",

    "@media(width >= 768px)": {
        flexDirection: "row",
    },
});

const BtnSend = styled("button")({
    borderRadius: "8px",
    padding: "0.5rem",
    width: "120px",
    fontSize: "14px !important",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
});

const Input = styled("input")({
    margin: 10,
    padding: 10,
    border: "1px grey solid",
    borderRadius: "5px",
    fontSize: "14px",
    outline: "none",

    "&:focus": {
        border: "1px black solid",
    },
});
