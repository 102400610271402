import jwtDecode from "jwt-decode";
import store from "../common/redux/store";
import { api, RouteAPI } from "../common/service/apiService";
import { storageService } from "../common/service/storageService";
import { setLogin } from "../login/redux/authSlice";

export const restoreUserSession = () => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            const { status } = await api(RouteAPI.AUTH_CHECK, "GET");

            if (status === 200) {
                const accessToken = storageService.getToken();
                const refreshToken = storageService.getRefreshToken();
                const id = storageService.getIdWithToken();

                if (!accessToken) {
                    throw new Error("No access token found");
                }

                if (!refreshToken) {
                    throw new Error("No refresh token found");
                }

                if (!id) {
                    throw new Error("No id found");
                }

                const { roles } = jwtDecode<{ roles: string[] }>(accessToken);
                store.dispatch(
                    setLogin({
                        token: accessToken,
                        refresh_token: refreshToken,
                        id: id,
                        roles: roles,
                        isLoggedIn: true,
                    })
                );
                resolve();
            }

            throw new Error("Unable to restore user session");
        } catch (error) {
            return reject(error);
        }
    });
};
