import {
    Box,
    Button,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { EmptyCard } from "../cards/EmptyCard";
import { StructureCard } from "../cards/StructureCard";
import { WorkerCard } from "../cards/WorkerCard";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { getInterestProfessionnels } from "../professionnels/redux/professionnelAction";
import { DisponibiliteProfessionnel } from "../search/model/Disponibilite";
import {
    DisponibiliteStructure,
    UserStructureResponse,
} from "../structures/model/StructureModel";
import {
    getEmptyAvatarStructure,
    getPictureFromProfilImage,
} from "../utils/FormatUtils";
import { language, LanguageType } from "../utils/LanguageUtils";

import "./DashboardPage.css";
import styled from "@emotion/styled";

export const Dashboard = () => {
    const [count, setCount] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState(0); // 0 - primele 3 proiecte, 1 - al 4-lea proiect
    const [personnelsMatched, setPersonnelMatched] = React.useState<
        DisponibiliteProfessionnel[] | []
    >([]);

    const professionnelMatchedSelector = useAppSelector(
        (state: RootState) => state.professionnel
    );
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );

    const idUser = useAppSelector((state: RootState) => state.user.data.id);
    const dispatch = useAppDispatch();
    const lang: LanguageType = language();
    const { t } = useTranslation();

    const isMobile = useMediaQuery("(max-width:990px)");

    const handleNextClick = () => {
        setCurrentIndex(1);
    };

    const handleBackClick = () => {
        setCurrentIndex(0);
    };

    useEffect(() => {
        dispatch(getInterestProfessionnels(idUser));
    }, [dispatch, idUser]);

    useEffect(() => {
        if (professionnelMatchedSelector.data[0]) {
            setPersonnelMatched(professionnelMatchedSelector.data);
            if (professionnelMatchedSelector.data[0].enabled)
                setCount(professionnelMatchedSelector.data.length);
        }
    }, [professionnelMatchedSelector]);

    const personnelsCalled = (props: DisponibiliteProfessionnel, i: number) => {
        return (
            <Grid item key={i} m={1} lg={6}>
                <WorkerCard {...props} key={i} />
            </Grid>
        );
    };

    const CompanyCalled = (props: DisponibiliteStructure, i: number) => {
        return (
            <Grid item card={6} key={i} lg={9} xs={7}>
                <StructureCard
                    {...props}
                    indexOfDisponibility={i}
                    key={i}
                    profilImageUrl={
                        userSelector.profileImage
                            ? getPictureFromProfilImage(
                                  userSelector.profileImage.contentUrl
                              )
                            : getEmptyAvatarStructure()
                    }
                />
            </Grid>
        );
    };

    if (!userSelector.societe) {
        return (
            <Stack
                sx={{
                    width: "100%",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        mx: 3,
                    }}
                >
                    {t("dashboard.professional.disclaimer")}
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <ContactContainer
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    mt: 4,
                }}
            >
                <Box
                    sx={{
                        width: "fit-content",
                        gridArea: "card",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            ml: 2,
                            mt: 3,
                        }}
                    >
                        {t("dashboard.personal.contacted")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 6,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                justifyContent: "center",
                                textAlign: "center",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {count}
                        </span>
                    </Typography>
                    <ContactCard>
                        {professionnelMatchedSelector.data
                            .filter((item: any, idx: number) => idx < 4)
                            .map(
                                (
                                    item: DisponibiliteProfessionnel,
                                    i: number
                                ) => {
                                    if (item.id.length > 1) {
                                        return personnelsCalled(item, i);
                                    }
                                    return null;
                                }
                            )}
                        {personnelsMatched.length <= 2 && (
                            <EmptyCard
                                message={lang.addPersonnel}
                                component={RouteApp.SEARCH}
                            ></EmptyCard>
                        )}
                    </ContactCard>
                </Box>
            </ContactContainer>
            <ProjectContainer>
                <Project>
                    <Stack
                        sx={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                mt: 3,
                                ml: 2,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {t("dashboard.projects")}
                            <span
                                style={{
                                    backgroundColor: "#3d3d3d",
                                    padding: 6,
                                    color: "white",
                                    borderRadius: "50%",
                                    width: "30px",
                                    height: "30px",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {userSelector.disponibilities.length}
                            </span>
                        </Typography>
                        {!isMobile && (
                            <Stack
                                sx={{
                                    display: "flex",
                                    width: "fit-content",
                                    justifyContent: "center",
                                    mt: 3,
                                }}
                            >
                                {currentIndex === 1 && (
                                    <Button
                                        onClick={handleBackClick}
                                        sx={{ textTransform: "uppercase" }}
                                    >
                                        <ArrowBackIosNewIcon
                                            sx={{ color: "#4F6F52" }}
                                        />
                                        Back
                                    </Button>
                                )}
                                {currentIndex === 0 &&
                                    userSelector.disponibilities.length > 3 && (
                                        <Button
                                            onClick={handleNextClick}
                                            sx={{ textTransform: "uppercase" }}
                                        >
                                            Next{" "}
                                            <ArrowForwardIosIcon
                                                sx={{ color: "#4F6F52" }}
                                            />
                                        </Button>
                                    )}
                            </Stack>
                        )}
                    </Stack>
                    <Cards>
                        {isMobile
                            ? userSelector.disponibilities.map((item, i) => {
                                  return CompanyCalled(item, i);
                              })
                            : currentIndex === 0
                            ? userSelector.disponibilities
                                  .slice(0, 3)
                                  .map((item, i) => {
                                      return CompanyCalled(item, i);
                                  })
                            : userSelector.disponibilities
                                  .slice(3, 4)
                                  .map((item, i) => {
                                      return CompanyCalled(item, i);
                                  })}
                        {userSelector.disponibilities.length <= 2 && (
                            <EmptyCard
                                message={lang.addProject}
                                component={RouteApp.MY_PROJECTS}
                            ></EmptyCard>
                        )}
                    </Cards>
                </Project>
            </ProjectContainer>
        </Stack>
    );
};

const ContactContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    mt: 4,

    "@media(width >= 990px)": {
        alignItems: "start",
    },
});

const ContactCard = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    mb: 2,

    "@media(width >= 990px)": {
        flexDirection: "row",
    },
});

const ProjectContainer = styled(Box)({
    display: "flex",
    marginTop: "1rem",
    background: "#DCDCDC",
    width: "100%",
    gap: 5,
    margin: 0,
    padding: 0,
});

const Project = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    "@media(width >= 990px)": {
        alignItems: "start",
        justifyContent: "center",
        width: "fit-content",
    },
});

const Cards = styled(Stack)({
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3rem",

    "@media(width >= 990px)": {
        flexDirection: "row",
        justifyContent: "start",
        margin: "0 0 1rem 1rem",
    },
});
