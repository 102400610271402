import { AnyAction, ThunkAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "../../common/redux/store";
import { RouteAPI, api } from "../../common/service/apiService";
import { storageService } from "../../common/service/storageService";
import { ResponseToken } from "../model/ResponseToken";
import { SignInData } from "../model/SignInData";
import {
    initialAuthData,
    setLogin,
    setLoginError,
    setLoginStart,
    setForgotPasswordError,
    setConfirmPasswordError,
    setConfirmPasswordSuccess,
    setForgotPasswordSuccess,
} from "./authSlice";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { disconnectUser } from "../../common/redux/userSlice";

export const login = (
    signInData: SignInData
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(setLoginStart());
        api(RouteAPI.LOGIN_API, "POST", {
            email: signInData.email,
            password: signInData.password,
        })
            .then((response: AxiosResponse<ResponseToken>) => {
                const responseToken: ResponseToken = response.data;
                storageService.setToken(responseToken.token);
                storageService.setRefreshToken(responseToken.refresh_token);
                storageService.setIdWithToken(responseToken.id);
                dispatch(setLogin(responseToken));
            })
            .catch((error: AxiosError<ResponseMessage>) => {
                dispatch(setLoginError(error));
            });
    };
};

export const logout = (
    isFromEditMyAccount?: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(invalidateToken());
        sessionStorage.removeItem("chatbotOpened");
    };
};

export const forgotPassword = (
    email: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        api(RouteAPI.FORGOT_PASSWORD_REQUEST, "POST", { email: email })
            .then((response: AxiosResponse<ResponseToken>) => {
                dispatch(setForgotPasswordSuccess());
            })
            .catch((error: AxiosError<ResponseMessage>) => {
                dispatch(setForgotPasswordError(error));
            });
    };
};

export const postNewPassword = (
    password: string,
    token: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        api(RouteAPI.FORGOT_PASSWORD_CONFIRM, "POST", {
            password: password,
            token: token,
        })
            .then((response: AxiosResponse<ResponseToken>) => {
                dispatch(setConfirmPasswordSuccess());
            })
            .catch((error: AxiosError<ResponseMessage>) => {
                dispatch(setConfirmPasswordError(error));
            });
    };
};

export async function refreshToken(): Promise<AxiosResponse<any, any>> {
    return await api(RouteAPI.REFRESH_TOKEN, "POST", {
        refresh_token: storageService.getRefreshToken(),
    });
}

export const invalidateToken = createAsyncThunk<any, boolean | undefined>(
    "auth/invalidateToken",
    async (isFromEditMyAccount, thunkAPI) => {
        try {
            storageService.removeToken();
            storageService.removeRefreshToken();
            storageService.removeIdWithToken();
            thunkAPI.dispatch(setLogin(initialAuthData));
            thunkAPI.dispatch(disconnectUser(undefined));
        } catch (error) {
            storageService.removeToken();
            storageService.removeRefreshToken();
            storageService.removeIdWithToken();
            thunkAPI.dispatch(setLogin(initialAuthData));
            thunkAPI.dispatch(disconnectUser(undefined));
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseMessage>
            );
        }
    }
);
