import axios, {
    AxiosError,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from "axios";
import { ResponseToken } from "../../login/model/ResponseToken";
import { refreshToken } from "../../login/redux/authAction";
import { RouteApp } from "../model/RouteApp";
import { RouteAPI, api } from "../service/apiService";
import { storageService } from "../service/storageService";

const redirectToLogin = () => {
    if (
        [
            RouteApp.LOGIN,
            RouteApp.SIGNUP,
            RouteApp.RESET_PASSWORD,
            RouteApp.FORGOT_PASSWORD,
            RouteApp.LEGACY,
        ].includes(window.location.pathname)
    )
        return;

    window.location.href = RouteApp.LOGIN;
};

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
        if (
            config.url &&
            config.url !== RouteAPI.REFRESH_TOKEN &&
            config.url !== RouteAPI.SIGN_UP
        ) {
            const token = storageService.getToken();
            if (token && config.headers) {
                config.headers["Authorization"] = "Bearer " + token;
            }
        }
        if (config.headers) {
            if (
                (config.url === RouteAPI.UPLOAD_IMAGE &&
                    config.method === "post") ||
                (config.data instanceof FormData &&
                    (config.data.has("files[]") ||
                        config.data.has("files") ||
                        config.data.has("file")))
            ) {
                config.headers["Content-Type"] = "multipart/form-data";
            } else if (config.method === "patch") {
                config.headers["Content-Type"] = "application/merge-patch+json";
            } else {
                config.headers["Content-Type"] = "application/json";
            }
        }
        return config;
    },
    (error: any) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error: AxiosError) => {
        if (
            error.response?.status === 401 &&
            error.config?.url !== RouteAPI.REFRESH_TOKEN &&
            error.config?.url !== RouteAPI.LOGIN
        ) {
            const originalRequest = error.config;

            if (storageService.getRefreshToken()) {
                try {
                    const responseToken = await refreshToken();
                    const { token, refresh_token, id } =
                        responseToken.data as ResponseToken;
                    storageService.setToken(token);
                    storageService.setRefreshToken(refresh_token);
                    storageService.setIdWithToken(id);
                } catch (error) {
                    storageService.removeToken();
                    storageService.removeRefreshToken();
                    storageService.removeIdWithToken();
                    storageService.setErrorMessageRefreshToken();
                    redirectToLogin();
                    return;
                }

                if (originalRequest) {
                    const postMethod = "post";
                    const putMethod = "put";
                    if (
                        originalRequest.method === "get" ||
                        originalRequest.method === "delete"
                    ) {
                        return api(
                            originalRequest.url!,
                            originalRequest.method
                        );
                    } else if (
                        originalRequest.method === "post" ||
                        originalRequest.method === "put"
                    ) {
                        return api(
                            originalRequest.url!,
                            originalRequest.method === postMethod
                                ? postMethod
                                : putMethod,
                            originalRequest?.data
                        );
                    }
                }
            } else {
                redirectToLogin();
            }
        } else if (error.config?.url === RouteAPI.REFRESH_TOKEN) {
            storageService.removeToken();
            storageService.removeRefreshToken();
            storageService.setErrorMessageRefreshToken();
            redirectToLogin();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
