import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { WorkerCard } from "../cards/WorkerCard";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { DisponibiliteProfessionnel } from "../search/model/Disponibilite";
import { RouteUtils } from "../utils/RouteUtils";
import { getInterestProfessionnels } from "./redux/professionnelAction";
import { useTranslation } from "react-i18next";

export const ProfessionnelPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const idUser = useAppSelector((state: RootState) => state.user.data.id);
    const professionnelMatchedSelector = useAppSelector(
        (state: RootState) => state.professionnel.data
    );

    const [professionnelMatched, setProfessionnelMatched] = useState<
        DisponibiliteProfessionnel[] | []
    >([]);

    useEffect(() => {
        dispatch(getInterestProfessionnels(idUser));
    }, [dispatch, idUser]);

    useEffect(() => {
        if (professionnelMatchedSelector) {
            setProfessionnelMatched(professionnelMatchedSelector);
        }
    }, [professionnelMatchedSelector]);

    const handleBackClick = useCallback(() => {
        navigate(RouteUtils.SEARCH);
    }, [navigate]);

    const personnelsCalled = useCallback(
        (props: DisponibiliteProfessionnel, i: number) => (
            <Grid item key={i} m={2} lg={5}>
                <WorkerCard {...props} />
            </Grid>
        ),
        []
    );

    const contactCount = useMemo(
        () => professionnelMatched.length,
        [professionnelMatched]
    );

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                gap: 4,
            }}
        >
            <Stack
                sx={{
                    width: "100%",
                    marginTop: "2.5rem",
                }}
            >
                <Typography
                    variant="h6"
                    mt={3}
                    sx={{ display: "flex", alignItems: "center" }}
                >
                    <Button
                        variant="text"
                        size="medium"
                        sx={{
                            padding: 0,
                            marginTop: "40px",
                            display: "contents",
                        }}
                        onClick={handleBackClick}
                    >
                        <ArrowBackIcon
                            fontSize="medium"
                            sx={{ height: 30, width: 30, mr: 1, ml: 2 }}
                        />
                    </Button>
                    {t("dashboard.personal.contacted")}
                    <Box
                        sx={{
                            backgroundColor: "#3d3d3d",
                            padding: 2,
                            color: "white",
                            borderRadius: "50%",
                            width: 24,
                            height: 24,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            ml: 1,
                        }}
                    >
                        {contactCount}
                    </Box>
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {professionnelMatched.map(personnelsCalled)}
                </Box>
            </Stack>
        </Box>
    );
};
